<template>
<div>
  <button class="btn btn-primary" @click="nativeAlert">Native Alert</button>
  <p>
    {{ data }}
  </p>
</div>
</template>

<script>
export default {
  data() {
    return {
      data: {none:"none"},
    }
  },
  mounted() {
    let that = this;
    window.actions = {
      return(params) {
        that.data = params;
      }
    }
  },
  methods: {
    nativeAlert() {
      if (window.webkit)
        window.webkit.messageHandlers.base.postMessage({msg:"Hello Native!"});
    }
  }
}
</script>

<style>

</style>